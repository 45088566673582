define('wedding-app/components/home-image', ['exports', 'ember', 'moment', 'numeral'], function (exports, _ember, _moment, _numeral) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ui', 'vertical', 'center', 'aligned', 'basic', 'segment', 'home-image'],
    clock: _ember['default'].inject.service(),
    'wedding-clock': _ember['default'].inject.service(),
    weddingDate: (0, _moment['default'])("2017-10-13T23:30:00Z"),

    timeTillWedding: _ember['default'].computed('wedding-clock.date', 'weddingDate', function () {
      console.log(this.get('clock'));
      var currentDate = (0, _moment['default'])(this.get('wedding-clock.date'));
      var weddingDate = this.get('weddingDate');

      var truncRegEx = /(^\d*)\.?\d*/;

      var timeDuration = _moment['default'].duration(weddingDate.diff(currentDate));
      console.log(timeDuration.asDays().toString().match(truncRegEx)[1]);

      var result = {
        days: (0, _numeral['default'])(timeDuration.asDays().toString().match(truncRegEx)[1]).format('000'),
        hours: (0, _numeral['default'])(timeDuration.hours().toString().match(truncRegEx)[1]).format('00'),
        minutes: (0, _numeral['default'])(timeDuration.minutes().toString().match(truncRegEx)[1]).format('00')
      };

      return result;
    })
  });
});