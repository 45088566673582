define('wedding-app/app', ['exports', 'ember', 'wedding-app/resolver', 'ember-load-initializers', 'wedding-app/config/environment'], function (exports, _ember, _weddingAppResolver, _emberLoadInitializers, _weddingAppConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _weddingAppConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _weddingAppConfigEnvironment['default'].podModulePrefix,
    Resolver: _weddingAppResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _weddingAppConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});