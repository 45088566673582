define('wedding-app/initializers/viewport-config', ['exports', 'ember-in-viewport/initializers/viewport-config'], function (exports, _emberInViewportInitializersViewportConfig) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberInViewportInitializersViewportConfig['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberInViewportInitializersViewportConfig.initialize;
    }
  });
});