define('wedding-app/router', ['exports', 'ember', 'wedding-app/config/environment'], function (exports, _ember, _weddingAppConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _weddingAppConfigEnvironment['default'].locationType,
    rootURL: _weddingAppConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('home');
    this.route('story');
    this.route('details');
    this.route('registry');
    this.route('contact');
    this.route('gallery');
  });

  exports['default'] = Router;
});