define('wedding-app/components/page-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend(_ember['default'].Evented, {
    classNames: ['page-header'],

    actions: {
      toggle: function toggle() {
        _ember['default'].$('.ui.sidebar').sidebar('toggle');
      }
    }
  });
});