define('wedding-app/components/photo-swipe', ['exports', 'ember-cli-photoswipe/components/photo-swipe'], function (exports, _emberCliPhotoswipeComponentsPhotoSwipe) {
  exports['default'] = _emberCliPhotoswipeComponentsPhotoSwipe['default'].extend({

    // OVERWRITE CAUSE APPARENTLY THESE GUYS DON'T UNDERSTAND
    // RELATIVE POSITIONING!!!
    _getBounds: function _getBounds(i) {
      var img = this.$('img').get(i),
          pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
          position = this.$(img).offset(),
          width = this.$(img).width();
      return { x: position.left, y: position.top + pageYScroll, w: width };
    }

  });
});